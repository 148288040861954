<template>
  <!--begin::Container-->
  <div v-if="$apollo.data.shipment" class="wrapper">
    <div id="printable">
      <div class="section-shaped my-0 skew-separator skew-mini ">
        <v-img
          class="page-header header-filter  header-small "
          max-height="250px"
          lazy-src="/media/biz-header.jpg"
          src="/media/test-biz-header.png"
          gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
        >
          <div class="container " style="padding-bottom: 65px;">
            <div class="md-layout justify-md-center ">
              <div
                class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
              >
                <div class="lato-header  font-weight-bolder text-white"></div>
                <div class="mt-5">
                  <h4 class="text-white "></h4>
                </div>
              </div>
            </div>
          </div>
        </v-img>
      </div>

      <div class="main main-raised" style="background:#fff;">
        <div class="container">
          <div class="text-lg-right">
            <v-btn
              elevation="9"
              rounded
              color="grey"
              @click="
                $router.push(
                  '/market/order-detail/' + shipment.order.identifier
                )
              "
            >
              <v-icon left>
                mdi-arrow-left
              </v-icon>
              Back
            </v-btn>
            <v-btn elevation="9" rounded color="grey" @click="printShipment">
              <v-icon left>
                mdi-printer
              </v-icon>
              Print
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 text-center">
            <v-card dark class="pt-3 pb-3">
              <h2>Shipment</h2>
            </v-card>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="container">
              <div>
                Sold To:
                {{ shipment.order.masterOrder.buyerCompany.company_name }}
              </div>
              <div class="row ">
                <div class="col-lg-4">
                  <v-sheet>
                    <div class="mt-6">
                      <div class="card-title">
                        <h3 class="card-label">Billing Info</h3>
                      </div>
                    </div>
                    <div class="card-body">
                      <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer</h5> -->

                      <p class="mb-2">
                        {{ shipment.billToAddress.company_name }}<br />
                        {{ shipment.billToAddress.address_line1 }}<br />
                        {{ shipment.billToAddress.address_line2 }}<br />
                        {{ shipment.billToAddress.city }}
                        {{ shipment.billToAddress.state }}
                        {{ shipment.billToAddress.zip_code }}
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2">Phone:</span>
                        {{ shipment.billToAddress.phone }}
                      </p>
                    </div>
                  </v-sheet>
                </div>
                <!-- end col -->
                <div class="col-lg-4">
                  <v-sheet>
                    <div class="mt-6">
                      <div class="card-title">
                        <h3 class="card-label">Shipping Info</h3>
                      </div>
                    </div>
                    <div class="card-body">
                      <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer Recieving</h5> -->

                      <p class="mb-2">
                        {{ shipment.shipToAddress.company_name }}<br />
                        {{ shipment.shipToAddress.address_line1 }}<br />
                        {{ shipment.shipToAddress.address_line2 }}<br />
                        {{ shipment.shipToAddress.city }}
                        {{ shipment.shipToAddress.state }}
                        {{ shipment.shipToAddress.zip_code }}
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2">Phone:</span>
                        {{ shipment.shipToAddress.phone }}
                      </p>
                    </div>
                  </v-sheet>
                </div>
                <!-- end col -->

                <div class="col-lg-4">
                  <v-card flat>
                    <ul class="list-unstyled mb-0">
                      <li>
                        <p class="mb-2">
                          <span class="font-weight-semibold mr-2"
                            >Purchase Order #:</span
                          >
                          {{ shipment.order.po }} <br />
                          Order #: {{ shipment.order.identifier }}<br />
                        </p>

                        <p class="mb-2">
                          <span class="font-weight-semibold mr-2"
                            >Shipment Date:</span
                          >
                          {{ shipment.created_at | date_format }}
                        </p>
                      </li>
                    </ul>
                  </v-card>
                </div>
                <!-- end col -->
              </div>

              <div class="row  ">
                <div class="col-lg-12">
                  Tracking Info:
                </div>

                <div class="col-lg-12">
                  <v-data-table
                    :headers="headers"
                    :items="shipment.trackingInfos"
                    sort-by="name"
                    class="elevation-1"
                  >
                  </v-data-table>
                </div>
              </div>
              <div class="row  ">
                <div class="col-lg-12">
                  <v-card class="card card-custom card-stretch">
                    <div class="card-header card-header-tabs-line">
                      <div class="card-title">
                        <h3 class="card-label">
                          Items from Shipment #{{ $route.params.id }}
                        </h3>
                      </div>
                      <div class="card-toolbar"></div>
                    </div>

                    <div class="card-body">
                      <!--begin: Datatable-->
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th class="text-left">Line ID</th>
                            <th style="width:280px;">SKU</th>
                            <th>Thumb</th>
                            <th style="width:280px;">Item</th>
                            <th style="width:100px;">Qty Shipped</th>
                            <th class="text-left">Price</th>
                            <th class="text-left">Subtotal</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, idx) in shipment.shipmentItems"
                            :key="idx"
                          >
                            <td>{{ item.orderItem.line }}</td>
                            <td>{{ item.orderItem.sku }}</td>
                            <td>
                              <v-img
                                class="d-flex align-self-center mr-3 rounded-circle"
                                :src="
                                  item.url != null
                                    ? baseurl + item.url
                                    : '/media/rollerskate.png'
                                "
                                alt="Generic placeholder image"
                                width="50"
                                height="50"
                              >
                              </v-img>
                            </td>
                            <td>
                              {{ item.orderItem.productName }}
                              <span v-if="item.orderItem.name != 'default'"
                                >({{ item.orderItem.name }})</span
                              >
                            </td>
                            <td>
                              {{ item.quantity }}
                            </td>
                            <td>
                              {{ item.orderItem.unitPrice | dollar_format }}
                            </td>
                            <td>
                              {{
                                (item.orderItem.unitPrice * item.quantity)
                                  | dollar_format
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="6" style="text-align: right;">
                              Subtotal
                            </td>
                            <td>
                              {{
                                subTotal(shipment.shipmentItems) | dollar_format
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                  </v-card>
                </div>
              </div>
              <!-- end row -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="shipment"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
    >
      <section slot="pdf-content">
        <print-shipment :shipment="shipment" />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import GetShipment from '@/graphql/queries/GetShipment.gql'
import VueHtml2pdf from 'vue-html2pdf'
import PrintShipment from '@/components/supplier/PrintShipment.vue'

export default {
  components: {
    VueHtml2pdf,
    PrintShipment,
  },
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
      return formatter.format(value)
    },
  },
  data: () => ({
    seller_company_name: '',
    baseurl: process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337',
    dialog: false,
    orderTab: 'Information',
    selected: 2,
    model: 'tab-2',
    headers: [
      { text: 'Carrier', value: 'carrier' },
      { text: 'Tracking', value: 'number' },
    ],
    orders: [],
    main_order_date: '',
    billto: [],
    shipto: [],
  }),
  apollo: {
    shipment: {
      query: GetShipment,
      variables() {
        return { id: this.$route.params.id }
      },
    },
  },
  methods: {
    ...mapActions(['getCompanyInfo']),
    subTotal(items) {
      return items.reduce((a, c) => a + c.orderItem.unitPrice, 0)
    },
    isInt(n) {
      return n % 1 === 0
    },
    printShipment() {
      window.scrollTo(0, 0)
      this.$refs.html2Pdf.generatePdf()
    },
  },
}
</script>
